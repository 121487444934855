import { ReactComponent as EditIcon } from "../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { useEffect, useState } from "react";
import { getRestorants } from "../database/restaurant";
import Pagination from "../components/pagination";
import { ReactComponent as LabelFull } from "../assets/icons/label-full.svg";
import { ReactComponent as Location } from "../assets/icons/send.svg";
import { ReactComponent as Hashtag } from "../assets/icons/hashtag.svg";
import { ReactComponent as Password } from "../assets/icons/password.svg";
import imageCompression from "browser-image-compression"; // Importa la librería de compresión
import Swal from "sweetalert2";
import loading from "../assets/logo_animado.svg";
import { capitalizeAll } from "../utils/functions";
import { createRestorant, editRestorantById } from "../database/restaurant";

export default function Restaurant() {
  const [waiters, setWaiters] = useState([]);

  const [logoShow, setLogoShow] = useState(null);
  const [logoEdit, setLogoEdit] = useState(null);

  const [modalRestorant, setModalRestorant] = useState({
    open: false,
    mode: "new",
    data: {
      name: "",
      address: "",
      code: "",
      pin: "",
    },
  });
  const [modalState, setModalState] = useState({
    mode: "create",
    open: false,
    username: "",
    pin: "",
    status: "active",
  });
  const [step, setStep] = useState(1); // Control de las páginas dentro del modal
  const handleSave = () => {
    const { mode, username, pin, status } = modalState;

    if (username.trim() === "" || pin.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Campos vacíos",
        text: "Por favor, revisa los datos ingresados.",
      });
      return;
    }

    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      if (mode === "create") {
        const newWaiter = {
          username: username,
          pin: pin,
          status: status || "active",
        };

        const updatedWaiters = sortWaiters([...waiters, newWaiter]);
        setWaiters(updatedWaiters);
      } else if (mode === "edit") {
        const updatedWaiters = waiters.map((waiter) => {
          if (waiter.pin === pin) {
            return {
              ...waiter,
              username: username,
              status: status || waiter.status,
            };
          }
          return waiter;
        });

        setWaiters(sortWaiters(updatedWaiters));
      }

      setModalState({
        open: false,
        mode: "create",
        username: "",
        pin: "",
        status: "active",
      });
      Swal.close();
    } catch (error) {
      console.error("Error al actualizar meseros:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al realizar la operación.",
      });
    } finally {
      Swal.close();
    }
  };

  const sortWaiters = (waitersArray) => {
    return waitersArray.sort((a, b) => a.username.localeCompare(b.username));
  };
  const handleModalSubmit = async () => {
    if (step === 1) {
      if (modalRestorant.mode === "edit") {
        const { name, address, code, pin, id } = modalRestorant.data;
        if (
          name.trim() === "" ||
          address.trim() === "" ||
          code.trim() === "" ||
          pin.trim() === ""
        ) {
          Swal.fire({
            icon: "warning",
            title: "Campos vacíos",
            text: "Por favor, revisa los datos ingresados.",
          });
          return;
        }
        let newRestorant = {
          id,
          name,
          address,
          pin,
          code,
        };
        if (logoEdit) {
          newRestorant.image = logoEdit;
        }
        const a = await editRestorantById(newRestorant);
        setRestorantsArray(a);
        console.log("Datos del restaurante:", newRestorant);
        if (a) {
          Swal.fire({
            icon: "success",
            title: "Restaurante modificado",
            text: "El restaurante se ha actualizado correctamente.",
          });
          setModalRestorant({
            open: false,
            mode: "new",
            data: {},
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Hubo un problema al modificar el restaurante.",
          });
        }

        // console.log("Datos del restaurante:", newRestorant);

        // setModalRestorant({
        //   open: false,
        //   mode: "new",
        //   data: {},
        // });
      } else {
        setStep(2);
      }
    } else {
      const { name, address, code, pin } = modalRestorant.data;
      if (
        name.trim() === "" ||
        address.trim() === "" ||
        code.trim() === "" ||
        pin.trim() === ""
      ) {
        Swal.fire({
          icon: "warning",
          title: "Campos vacíos",
          text: "Por favor, revisa los datos ingresados.",
        });
        return;
      }
      let newRestorant = {
        name,
        address,
        code,
        pin,
        image: logo,
        users: waiters,
        pos: [],
        tables: [],
        status: "active",
      };
      const a = await createRestorant(newRestorant);
      setRestorantsArray(a);
      console.log("Datos del restaurante:", a);

      if (a) {
        Swal.fire({
          icon: "success",
          title: "Restaurante creado",
          text: "El restaurante se ha creado correctamente.",
        });
        setModalRestorant({
          open: false,
          mode: "new",
          data: {},
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al crear el restaurante.",
        });
      }

      console.log("Datos del restaurante:", newRestorant);

      // setModalRestorant({
      //   open: false,
      //   mode: "new",
      //   data: {},
      // });
    }
  };
  const [restorantsArray, setRestorantsArray] = useState([]);
  const [imageDetails, setImageDetails] = useState(null);
  const [logo, setLogo] = useState(null);
  const handleBack = () => {
    setStep(1); // Vuelve a la primera página
  };

  useEffect(() => {
    const getRestorantsRegitered = async () => {
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      let restorants = await getRestorants();
      if (restorants.length > 0) {
        setRestorantsArray(restorants);
        Swal.close();
      } else {
        Swal.fire({
          icon: "info",
          title: "No hay restaurantes registrados",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };
    getRestorantsRegitered();
  }, []);

  const generarNumerosAleatorios = (length = 4) => {
    const digitos = "0123456789";
    return Array.from(
      { length },
      () => digitos[Math.floor(Math.random() * digitos.length)]
    ).join("");
  };

  const handleOpenModal = (mode, datas) => {
    console.log(mode);
    if (mode === "new") {
      console.log("a");
      const initialData = {
        name: "",
        address: "",
        code: generarNumerosAleatorios(6),
        pin: generarNumerosAleatorios(4),
      };
      setModalRestorant({ open: true, mode: "new", data: initialData });
    } else {
      console.log("data", datas);
      const initialData = {
        id: datas.id,
        name: datas.name,
        address: datas.address,
        code: datas.code,
        pin: datas.pin,
        image: datas.image,
      };
      console.log("initialData", initialData);
      setModalRestorant({ open: true, mode, data: initialData });
    }
  };

  const handleInputChange = (field, value) => {
    setModalRestorant({
      ...modalRestorant,
      data: {
        ...modalRestorant.data,
        [field]: value,
      },
    });
  };

  // Función para manejar la carga y compresión de imágenes
  // Función para manejar la carga y compresión de imágenes
  const handleFileChange = async (event) => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const file = event.target.files[0];

    if (!file) {
      Swal.close();
      return;
    }

    console.log("Tamaño inicial:", (file.size / 1024 / 1024).toFixed(2), "MB");

    const options = {
      maxSizeMB: 1, // Tamaño máximo
      maxWidthOrHeight: 1024, // Máxima dimensión
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log("Imagen comprimida:", compressedFile);
      console.log(
        "Tamaño comprimido:",
        (compressedFile.size / 1024 / 1024).toFixed(2),
        "MB"
      );
      setLogo(compressedFile);

      const logoURL = URL.createObjectURL(compressedFile); // Crear URL
      console.log("URL de la imagen:", logoURL);

      setLogoShow(logoURL);

      Swal.close();
      setImageDetails({
        originalSize: (file.size / 1024 / 1024).toFixed(2),
        compressedSize: (compressedFile.size / 1024 / 1024).toFixed(2),
      });
    } catch (error) {
      Swal.close();
      console.error("Error al comprimir la imagen:", error);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileChange(event);
  };

  const handleFileChangeEdit = async (e) => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const file = e.target.files[0];

    if (!file) {
      Swal.close();
      return;
    }

    const options = {
      maxSizeMB: 1, // Tamaño máximo
      maxWidthOrHeight: 1024, // Máxima dimensión
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setLogoEdit(compressedFile);

      const logoURL = URL.createObjectURL(compressedFile); // Crear URL
      setModalRestorant((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          image: logoURL,
        },
      }));
      Swal.close();
    } catch (error) {
      Swal.close();
      console.error("Error al comprimir la imagen:", error);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full max-w-screen-xl mx-auto pb-10">
        <div className="flex items-center justify-between px-4 py-3">
          <h1 className="text-orangeFoodiv text-3xl font-semibold">
            Restaurantes
          </h1>
          <button
            className="flex items-center text-orangeFoodiv p-3 rounded-2xl w-auto hover:bg-orange-100 border-2 border-orange-100"
            aria-label="Agregar nueva mesa"
            onClick={() => handleOpenModal("new")}
          >
            <Plus className="mr-2" />
            Nuevo Restaurante
          </button>
        </div>
        <div className="flex justify-start space-x-4 my-4">
          <button
            className={`px-4 py-2 rounded-xl bg-gray-200`}
            onClick={() => ""}
          >
            Todos
          </button>
          <button className={`px-4 py-2 rounded-xl border bg-gray-200`}>
            Activos
          </button>
          <button className={`px-4 py-2 rounded-xl border bg-gray-200`}>
            Inactivos
          </button>
        </div>
        <div className="w-full">
          <Pagination
            items={restorantsArray}
            itemsPerPage={6}
            setRestorantsArray={setRestorantsArray}
            handleOpenModal={handleOpenModal}
          />
        </div>
      </div>

      {modalRestorant.open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center px-4 sm:px-6">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg flex flex-col">
            <h2 className="text-2xl mb-4 text-orangeFoodiv text-center">
              {modalRestorant.mode === "new"
                ? "Nuevo restaurante"
                : "Editar restaurante"}
            </h2>
            {step === 1 && (
              <div>
                {/* Página 1: Formulario */}
                <div className="grid grid-cols-2 gap-4">
                  {[
                    { field: "address", label: "Dirección", Icon: Location },
                    { field: "name", label: "Nombre", Icon: LabelFull },
                    { field: "code", label: "Codigo", Icon: Hashtag },
                    { field: "pin", label: "Pin", Icon: Password },
                  ].map(({ field, label, Icon }) => (
                    <div className="relative mb-6" key={field}>
                      <Icon className="w-6 h-6 absolute left-3 text-gray-500 top-1/2 transform -translate-y-1/2" />
                      <input
                        id={field}
                        placeholder=" "
                        value={modalRestorant.data[field]}
                        onChange={(e) =>
                          handleInputChange(field, e.target.value)
                        }
                        className="block h-14 w-full px-4 pt-4 pb-2 pl-12 text-lg text-gray-900 font-medium bg-transparent border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-1 focus:ring-orangeFoodiv focus:border-orangeFoodiv"
                      />
                      <label
                        htmlFor={field}
                        className="absolute text-sm text-gray-500 top-0 left-8 px-2 -mt-2 bg-white"
                      >
                        {label}
                      </label>
                    </div>
                  ))}
                </div>

                <h2 className="text-lg mb-4 text-orangeFoodiv text-start">
                  Logo del restaurante
                </h2>
                <div
                  className="relative mb-6 min-h-[200px] flex flex-col items-center justify-center border-2 border-dashed border-orangeFoodiv rounded-lg p-4 sm:min-h-[300px] cursor-pointer"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  {modalRestorant.mode === "new" ? (
                    <>
                      {logoShow ? (
                        <img
                          src={logoShow}
                          alt="Logo del restaurante"
                          className="w-32 h-32 sm:w-56 sm:h-56 object-cover rounded-full mb-4"
                          onClick={() => setLogo(null)}
                        />
                      ) : (
                        <p className="text-center text-gray-500">
                          Haz clic o arrastra una imagen aquí
                        </p>
                      )}

                      <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </>
                  ) : (
                    <>
                      {modalRestorant.data.image ? (
                        <img
                          src={modalRestorant.data.image}
                          alt="Logo del restaurante"
                          className="w-32 h-32 sm:w-56 sm:h-56 object-cover rounded-full mb-4"
                        />
                      ) : (
                        <p className="text-center text-gray-500">
                          Haz clic o arrastra una imagen aquí
                        </p>
                      )}

                      <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChangeEdit}
                        className="hidden"
                      />
                    </>
                  )}
                </div>
              </div>
            )}

            {step === 2 && (
              <div>
                <h2 className="text-lg mb-2 text-orangeFoodiv text-start">
                  Meseros
                </h2>
                {waiters.length > 0 ? (
                  <div>
                    <div className="grid grid-cols-1 gap-4">
                      {waiters.map((waiter, i) => (
                        <div
                          key={i}
                          className="flex items-center justify-between p-4 bg-gray-100 rounded-lg"
                        >
                          <div className="flex items-center space-x-4">
                            <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                              {waiter.username[0]}
                            </div>
                            <div>
                              <h3 className="text-lg font-semibold">
                                {waiter.username}
                              </h3>
                              <p className="text-sm text-gray-500">
                                PIN: {waiter.pin}
                              </p>
                            </div>
                          </div>
                          <div>
                            <button
                              className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
                              onClick={() =>
                                setModalState({
                                  open: true,
                                  mode: "edit",
                                  ...waiter,
                                })
                              }
                            >
                              <EditIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className=" items-center justify-end my-2 flex flex-col">
                      <button
                        onClick={() =>
                          setModalState({
                            ...modalState,
                            open: true,
                            pin: generarNumerosAleatorios(4),
                          })
                        }
                        className="bg-orangeFoodiv text-white px-4 py-2 my-10 rounded-2xl hover:bg-orange-500"
                      >
                        nuevo mesero
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <h2 className="text-sm mb-4 text-gray-500 text-start">
                      Debe crear almenos un mesero
                    </h2>
                    <div className="p-6 items-center justify-center my-10 flex flex-col">
                      <button
                        onClick={() =>
                          setModalState({
                            ...modalState,
                            open: true,
                            pin: generarNumerosAleatorios(4),
                          })
                        }
                        className="bg-orangeFoodiv text-white px-4 py-2 my-10 rounded-2xl hover:bg-orange-500"
                      >
                        Crear mesero
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="flex justify-end">
              <button
                onClick={() => {
                  setModalRestorant({
                    open: false,
                    mode: "new",
                    data: {},
                  });
                  setLogo(null);
                  setLogoEdit(null);
                }}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>

              {modalRestorant.mode === "new" ? (
                <>
                  {step === 1 ? (
                    <button
                      onClick={() => {
                        handleModalSubmit();
                      }}
                      className="bg-orangeFoodiv text-white px-4 py-2  rounded-2xl hover:bg-orange-500"
                    >
                      Siguiente
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          handleBack();
                        }}
                        className="bg-gray-300 text-gray-700 px-4 py-2  rounded-2xl hover:bg-gray-400 mr-2"
                      >
                        Volver atrás
                      </button>
                      <button
                        onClick={() => {
                          handleModalSubmit();
                        }}
                        className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
                      >
                        Guardar
                      </button>
                    </>
                  )}
                </>
              ) : (
                <button
                  onClick={() => {
                    handleModalSubmit();
                  }}
                  className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
                >
                  Guardar
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {modalState.open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col text-center">
            <h2 className="text-2xl mb-4 text-orangeFoodiv">
              {modalState.mode === "create" ? "Nuevo mesero" : "Editar mesero"}
            </h2>
            <input
              placeholder="Nombre del mesero"
              value={capitalizeAll(modalState.username)}
              onChange={(e) =>
                setModalState({
                  ...modalState,
                  username: capitalizeAll(e.target.value),
                })
              }
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
            />
            <input
              placeholder="PIN"
              value={modalState.pin}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 4) {
                  setModalState({ ...modalState, pin: value });
                }
              }}
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
            />
            <select
              value={modalState.status || "active"} // Estado por defecto
              onChange={(e) =>
                setModalState({ ...modalState, status: e.target.value })
              }
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
            >
              <option value="active">Activo</option>
              <option value="inactive">Inactivo</option>
            </select>
            <div className="flex justify-end">
              <button
                onClick={() =>
                  setModalState({
                    open: false,
                    mode: "create",
                    username: "",
                    pin: "",
                    status: "active",
                  })
                }
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
              >
                {modalState.mode === "create" ? "Crear" : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

{
  /* {isModalDeleteOpen.open && (
     
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div
            ref={modalDeleteRef}
            className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col items-center"
          >
            <TrashIcon className="text-orangeFoodiv w-28 h-28 mb-3" />
            <h2 className="text-lg font-semibold mb-4">
              ¿Estás seguro de eliminar esta mesa?
            </h2>
            <div className="flex justify-end items-end">
              <button
                // onClick={() => setIsModalDeleteOpen({ open: false, id: null })}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>
              <button
                // onClick={() => deleteTable(isModalDeleteOpen.id)}
                className="bg-orangeFoodiv text-white px-4 py-2 rounded hover:bg-red-300"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )} */
}
