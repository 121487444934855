import DatePicker from "react-datepicker";
import ResponsiveChart from "../components/chart_component";
import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDailyReport,
  getProductsReport,
  geyMonthlyReport,
} from "../database/reports";
import { useSelector } from "react-redux";
import { ReactComponent as Calendar2 } from "../assets/icons/calendar-2.svg";
import { capitalize, formatToCLP } from "../utils/functions";
import es from "date-fns/locale/es";
import Swal from "sweetalert2";
import loading from "../assets/logo_animado.svg";
export default function Reports() {
  const restaurantId = useSelector((state) => state.auth?.userData?.id) || "";
  const [isTabSelected, setIsTabSelected] = useState(false);
  const [dataView, setDataView] = useState(false);
  const dropMenuRef = useRef(null);
  const [dataPoints, setDataPoints] = useState([0, 0, 0, 0, 0]);
  const [dataToday, setDataToday] = useState({
    total: 0,
    cardPays: 0,
    cashPays: 0,
    tips: 0,
  });
  const [typeProduct, setTypeProduct] = useState("food");
  const totalMonto = dataToday.total + dataToday.tips;
  const today = new Date();
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  const [dataProducts, setDataProducts] = useState();
  const calculatePercentage = (value) =>
    totalMonto > 0 ? ((value / totalMonto) * 100).toFixed(0) : 0;
  const cardPercentage = calculatePercentage(dataToday.cardPays);
  const cashPercentage = calculatePercentage(dataToday.paysCash);
  const tipsPercentage = calculatePercentage(dataToday.tips);

  const [startDateRangeProducts, setStartDateRangeProducts] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDateRangeProducts, setEndDateRangeProducts] = useState(new Date());

  const [startDateRangeSales, setStartDateRangeSales] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDateRangeSales, setEndDateRangeSales] = useState(new Date());

  const compare = (today, back) => {
    if (today > back) return "Ganancia";
    if (today < back) return "Pérdida";
    return "Sin cambios";
  };

  // Event handlers
  const handleClickOutside = (e) => {
    if (
      dropMenuRef.current &&
      !dropMenuRef.current.contains(e.target) &&
      !e.target.classList.contains("menu-button")
    ) {
      setIsTabSelected(false);
    }
  };

  const toggleDataView = () => setDataView(!dataView);

  const toggleView = () => setIsTabSelected(!isTabSelected);

  const handleDatePickerSales = async (dates) => {
    const [start, end] = dates;
    setStartDateRangeSales(start);
    setEndDateRangeSales(end);
    if (start && end) {
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      const report = await getDailyReport(start, end, restaurantId);
      if (report) {
        Swal.close();
      }
    }
  };

  const handleDatePickerProducts = async (dates) => {
    const [start, end] = dates;
    setStartDateRangeProducts(start);
    setEndDateRangeProducts(end);
    if (start && end) {
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      const report = await getProductsReport(start, end, restaurantId);
      if (report) {
        Swal.close();
      }
    }
  };

  // Effects
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (!restaurantId) return;
    const fetchData = async () => {
      let data = await geyMonthlyReport(restaurantId);
      setDataPoints(data);
      console.log(data);
    };
    fetchData();
  }, [restaurantId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDateRangeSales || !endDateRangeSales) return;
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const report = await getDailyReport(
        startDateRangeSales,
        endDateRangeSales,
        restaurantId
      );
      if (report) {
        setDataToday(
          report.dataToday || {
            total: 0,
            cardPays: 0,
            cashPays: 0,
            tips: 0,
            paysCash: 0,
            paysDebit: 0,
            paysCredit: 0,
          }
        );
        Swal.close();
      }
    };
    fetchData();
  }, [startDateRangeSales, endDateRangeSales, restaurantId]);

  useEffect(() => {
    const fetchDataProducts = async () => {
      if (!startDateRangeProducts || !endDateRangeProducts) return;
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const report = await getProductsReport(
        startDateRangeProducts,
        endDateRangeProducts,
        restaurantId
      );
      if (report) {
        setDataProducts(report);
        Swal.close();
      }
    };
    fetchDataProducts();
  }, [startDateRangeProducts, endDateRangeProducts, restaurantId]);

  return (
    <div className="w-full">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-4 py-3 mb-2">
          <h1 className="text-orangeFoodiv text-3xl font-semibold mb-4 md:mb-0">
            Reportes
          </h1>
          <div className="flex flex-row w-full md:w-auto space-x-4 rounded-xl p-2">
            <button
              className={`w-full md:w-52 font-semibold  text-center whitespace-nowrap rounded-3xl px-4 py-2 ${
                !isTabSelected
                  ? "bg-backgroundFoodiv text-orangeFoodiv border-[3px] border-orangeFoodiv"
                  : "bg-gray-100 text-gray-800 border-[3px] border-gray-300"
              }`}
              onClick={toggleView}
            >
              Ventas
            </button>
            <button
              className={`w-full md:w-52 text-center font-semibold whitespace-nowrap rounded-3xl px-4 py-2 ${
                isTabSelected
                  ? "bg-backgroundFoodiv text-orangeFoodiv border-[3px] border-orangeFoodiv"
                  : "bg-gray-100 text-gray-800 border-[3px] border-gray-300"
              }`}
              onClick={toggleView}
            >
              Productos
            </button>
          </div>
        </div>
        {!isTabSelected ? (
          <>
            <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
              <div className="w-full p-4">
                <ResponsiveChart dataPoints={dataPoints} />
              </div>
              <div className="w-full flex flex-col p-4 relative">
                <span className="absolute top-[27px] left-6 flex items-center text-gray-400 pointer-events-none">
                  <Calendar2 className="text-[#243b3b]" />
                </span>
                <DatePicker
                  selectsRange
                  startDate={startDateRangeSales}
                  endDate={endDateRangeSales}
                  onChange={handleDatePickerSales}
                  locale={es}
                  dateFormat="dd-MM-yyyy"
                  className="p-3 pl-10 bg-[#243b3b]/10 rounded-xl w-full text-[#243b3b]/60 text-base mb-4 focus:outline-none text-center"
                  placeholderText="Seleccionar rango de fechas"
                  maxDate={today}
                  minDate={twoMonthsAgo}
                  popperPlacement="bottom"
                />
                <div className="w-full border border-orangeFoodiv rounded-2xl flex flex-col p-6">
                  <h5 className="text-center text-lg font-bold text-gray-500 mb-4">
                    Total
                  </h5>
                  <h3 className="text-center text-4xl font-extrabold text-orangeFoodiv mb-6">
                    {formatToCLP(
                      dataToday.paysCash +
                        dataToday.tips +
                        dataToday.paysDebit +
                        dataToday.paysCredit
                    )}
                  </h3>
                  <div className="w-full flex flex-row md:flex-row justify-between gap-4">
                    <div className="flex-1 flex flex-col justify-center items-center">
                      <h5 className="text-2xl font-semibold text-gray-700">
                        {cardPercentage}%
                      </h5>
                      <h5 className="text-sm font-semibold text-gray-500">
                        Tarjetas
                      </h5>
                    </div>
                    <div className="flex-1 flex flex-col justify-center items-center">
                      <h5 className="text-2xl font-semibold text-gray-700">
                        {cashPercentage}%
                      </h5>
                      <h5 className="text-sm font-semibold text-gray-500">
                        Efectivo
                      </h5>
                    </div>
                    <div className="flex-1 flex flex-col justify-center items-center">
                      <h5 className="text-2xl font-semibold text-gray-700">
                        {tipsPercentage}%
                      </h5>
                      <h5 className="text-sm font-semibold text-gray-500">
                        Propinas
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full p-8 bg-white border-2 border-gray-300 rounded-[30px] mb-4">
              <div className="self-stretch h-[106px] flex-col justify-start items-start flex mb-2">
                <div className="self-stretch text-[#243b3b]/60 text-[22px] font-bold font-['Nunito'] leading-7">
                  Detalle efectivo
                </div>
                <div className="self-stretch py-1 justify-start items-center gap-[29px] inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-center items-center gap-2 flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="self-stretch text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                        Pagos en efectivo:
                      </div>
                    </div>
                  </div>
                  <div className="text-right text-[#1f2727] text-sm font-normal font-['Nunito'] leading-tight tracking-tight">
                    {dataToday.cashPays}
                  </div>
                </div>
                <div className="self-stretch py-[11px] justify-between items-center inline-flex">
                  <div className="text-center text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                    Total:
                  </div>
                  <div className="text-center text-[#f46246] text-sm font-medium font-['Nunito'] uppercase leading-tight tracking-tight">
                    {formatToCLP(dataToday.paysCash)}
                  </div>
                </div>
              </div>

              <div className="self-stretch h-auto  flex-col justify-start items-start flex mb-2">
                <div className="self-stretch text-[#243b3b]/60 text-[22px] font-bold font-['Nunito'] leading-7">
                  Detalle tarjetas
                </div>
                <div className="self-stretch py-1 justify-start items-center gap-[29px] inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-center items-center gap-2 flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="self-stretch text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                        Pagos con tarjeta:
                      </div>
                    </div>
                  </div>
                  <div className="text-right text-[#1f2727] text-sm font-normal font-['Nunito'] leading-tight tracking-tight">
                    {dataToday.cardPays}
                  </div>
                </div>
                <div className="self-stretch py-1 justify-start items-center gap-[29px] inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-center items-center gap-2 flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="self-stretch text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                        Total tarjeta de crédito:
                      </div>
                    </div>
                  </div>
                  <div className="text-right text-[#1f2727] text-sm font-medium font-['Nunito'] uppercase leading-tight tracking-tight">
                    {formatToCLP(dataToday.paysCredit)}
                  </div>
                </div>
                <div className="self-stretch py-1 justify-start items-center gap-[29px] inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-center items-center gap-2 flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="self-stretch text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                        Total tarjeta de débito :
                      </div>
                    </div>
                  </div>
                  <div className="text-right text-[#1f2727] text-sm font-medium font-['Nunito'] uppercase leading-tight tracking-tight">
                    {formatToCLP(dataToday.paysDebit)}
                  </div>
                </div>
                <div className="self-stretch py-[11px] justify-between items-center inline-flex">
                  <div className="text-center text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                    Total:
                  </div>
                  <div className="text-center text-[#f46246] text-sm font-medium font-['Nunito'] uppercase leading-tight tracking-tight">
                    {formatToCLP(dataToday.paysCredit + dataToday.paysDebit)}
                  </div>
                </div>
              </div>

              <div className="self-stretch h-[124px] flex-col justify-start items-start flex">
                <div className="self-stretch text-[#243b3b]/60 text-[22px] font-bold font-['Nunito'] leading-7">
                  Detalle Final
                </div>
                <div className="self-stretch py-1 justify-start items-center gap-[29px] inline-flex">
                  <div className="grow shrink basis-0 h-6 justify-center items-center gap-2 flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <div className="self-stretch text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                        propinas:
                      </div>
                    </div>
                  </div>
                  <div className="text-right text-[#1f2727] text-sm font-medium font-['Nunito'] uppercase leading-tight tracking-tight">
                    {formatToCLP(dataToday.tips)}
                  </div>
                </div>

                <div className="self-stretch py-[11px] justify-between items-center inline-flex">
                  <div className="text-center text-[#243b3b]/60 text-base font-normal font-['Nunito'] leading-normal tracking-wide">
                    Total pagos:
                  </div>
                  <div className="text-center text-[#f46246] text-sm font-medium font-['Nunito'] uppercase leading-tight tracking-tight">
                    {formatToCLP(dataToday.paysCash + dataToday.tips)}
                  </div>
                </div>
              </div>
            </div>
            <br />
          </>
        ) : (
          <>
            <div className="w-full flex flex-col lg:flex-col mt-4 ">
              <div className="w-full flex flex-col p-4 relative">
                <span className="absolute top-[27px] left-6 flex items-center text-gray-400 pointer-events-none">
                  <Calendar2 className="text-[#243b3b]" />
                </span>
                <DatePicker
                  selectsRange
                  startDate={startDateRangeProducts}
                  endDate={endDateRangeProducts}
                  onChange={handleDatePickerProducts}
                  locale={es}
                  dateFormat="dd-MM-yyyy"
                  className="p-3 bg-[#243b3b]/10 rounded-xl w-full mb-4 text-[#243b3b]/60 text-base focus:outline-none text-center"
                  placeholderText="Seleccionar rango de fechas"
                  maxDate={today}
                  minDate={twoMonthsAgo}
                  popperPlacement="bottom"
                />
              </div>

              <div className="w-full p-4 ">
                <ResponsiveChart dataPoints={dataPoints} />
              </div>
              <div className="w-full flex flex-col p-4 relative"></div>
            </div>

            <div className="flex flex-col w-full mb-8 justify-between h-32 md:flex-row md:h-auto    rounded-xl p-2">
              <button
                className={`w-full md:w-[49%] font-semibold text-center whitespace-nowrap rounded-3xl px-4 py-2 ${
                  !dataView
                    ? "bg-backgroundFoodiv text-orangeFoodiv border-[3px] border-orangeFoodiv"
                    : "bg-gray-100 text-gray-800 border-[3px] border-gray-300"
                }`}
                onClick={() => {
                  setTypeProduct("food");
                  toggleDataView();
                }}
              >
                Comida
              </button>

              <button
                className={`w-full  md:w-[49%]  font-semibold text-center whitespace-nowrap rounded-3xl px-4 py-2 ${
                  dataView
                    ? "bg-backgroundFoodiv text-orangeFoodiv border-[3px] border-orangeFoodiv"
                    : "bg-gray-100 text-gray-800 border-[3px] border-gray-300"
                }`}
                onClick={() => {
                  setTypeProduct("drink");
                  toggleDataView();
                }}
              >
                Bebida
              </button>
            </div>

            <div className="w-full p-8 bg-white border-2 border-gray-300 rounded-[30px] mb-10">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="border-b-2 border-gray-300">
                    <th className="text-left py-2 w-[50%] md:w-[85%]">Item</th>
                    <th className="text-right py-2">Cant.</th>
                    <th className="text-right py-2">Precio</th>
                  </tr>
                </thead>
                {typeProduct === "food" ? (
                  <tbody>
                    {dataProducts?.isFood && dataProducts?.isFood.length > 0 ? (
                      dataProducts?.isFood.map((e, i) => (
                        <tr key={i} className="border-b border-gray-200">
                          <td className="py-2 w-[50%] md:w-[85%]">
                            {capitalize(e.product)}
                          </td>
                          <td className="py-2 text-right">{e.quantity}</td>
                          <td className="py-2 text-right text-orangeFoodiv">
                            {formatToCLP(e.unitPrice)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="py-2 text-center" colSpan="3">
                          No hay productos de comida vendidos
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {dataProducts.isDrink && dataProducts.isDrink.length > 0 ? (
                      dataProducts.isDrink.map((e, i) => (
                        <tr key={i} className="border-b border-gray-200">
                          <td className="py-2 w-[50%] md:w-[85%]">
                            {capitalize(e.product)}
                          </td>
                          <td className="py-2 text-right">{e.quantity}</td>
                          <td className="py-2 text-right text-orangeFoodiv">
                            {formatToCLP(e.unitPrice)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="py-2 text-center" colSpan="3">
                          No hay productos de bebidas vendidos
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>

            <br />
          </>
        )}
      </div>
    </div>
  );
}
