import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import loading from "../assets/logo_animado.svg";
import { deleteProduct, updateCatalog } from "../database/catalog";
import { ReactComponent as AlignLeft } from "../assets/icons/align-left.svg";
import { ReactComponent as DollarSign } from "../assets/icons/dollar-sign.svg";
import { ReactComponent as LabelFull } from "../assets/icons/label-full.svg";
import { ReactComponent as Package } from "../assets/icons/package.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";

import { capitalize } from "../utils/functions";

export default function DialogProducts({
  type,
  updateData,
  restaurantId,
  categories,
  open,
  onClose,
  catalogId,
  setCategories,
  categorySelected,
  setModalCategori,
}) {
  const [producName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [isDrink, setIsDrink] = useState("");
  const modalProductsRef = useRef(null);

  useEffect(() => {
    if (type === "edit") {
      setProductName(updateData.producName || "");
      setPrice(updateData.price || "");
      setDescription(updateData.description || "");
      setCategory(updateData.categoryName || "");
      setIsDrink(updateData.isDrink || false);
    }
  }, [type, updateData]);

  if (!open) return null;

  const handleDeleteProdut = async () => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (!producName) {
      Swal.fire({
        icon: "warning",
        title: "Producto no seleccionado",
        text: "Por favor selecciona un producto para eliminar.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Estas por borrar un producto.",
      text: "¿seguro que quieres eliminar este producto?",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar",
    }).then(async (e) => {
      if (e.isConfirmed) {
        try {
          await deleteProduct(producName, restaurantId);
          const updatedCategories = categories.map((category) => {
            return {
              ...category,
              products: category.products.filter(
                (product) => product.name !== updateData.producName
              ),
            };
          });
          setCategories(updatedCategories);
          handleCloseAndClean();
          Swal.fire({
            icon: "success",
            title: "Producto Eliminado",
            text: "Producto eliminado correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        } catch (error) {
          console.error("Error al eliminar el producto:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error al eliminar el producto. Inténtalo nuevamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    });
  };

  const handleDrinkChange = () => {
    setIsDrink(true);
  };

  const handleEdibleChange = () => {
    setIsDrink(false);
  };
  const handleCloseAndClean = () => {
    setCategory("");
    setDescription("");
    setIsDrink(false);
    setProductName("");
    setPrice("");
    onClose();
  };

  const createProduct = async () => {
    if (!producName || !price || !category) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, completa todos los campos obligatorios.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    Swal.fire({
      imageUrl: loading,
      title: "Creando producto...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      const updatedCategories = categories.map((cat) => {
        if (cat.name === category) {
          return {
            ...cat,
            products: [
              ...(cat.products || []),
              { name: producName, price, description, isDrink },
            ],
          };
        }
        return cat;
      });
      await updateCatalog(catalogId, { categories: updatedCategories });
      setCategories(updatedCategories);

      Swal.fire({
        icon: "success",
        title: "Producto creado",
        text: "El producto ha sido creado correctamente.",
        timer: 1400,
        showConfirmButton: false,
      });
      handleCloseAndClean();
    } catch (error) {
      console.error("Error al crear producto:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo crear el producto.",
      });
    }
  };
  const updateProduct = async () => {
    if (!producName || !price || !category) {
      Swal.fire({
        icon: "warning",
        title: "Campos incompletos",
        text: "Por favor, completa todos los campos obligatorios.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    Swal.fire({
      imageUrl: loading,
      title: "Actualizando producto...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      const updatedCategories = categories.map((cat) => {
        if (
          cat.name === updateData.categoryName &&
          category !== updateData.categoryName
        ) {
          const updatedProducts = cat.products.filter(
            (product) => product.name !== updateData.producName
          );
          return { ...cat, products: updatedProducts };
        }

        if (cat.name === category && category !== updateData.categoryName) {
          return {
            ...cat,
            products: [
              ...cat.products,
              { name: producName, price, description, isDrink },
            ],
          };
        }

        if (cat.name === updateData.categoryName) {
          const updatedProducts = cat.products.map((product) =>
            product.name === updateData.producName
              ? { name: producName, price, description, isDrink }
              : product
          );
          return { ...cat, products: updatedProducts };
        }

        return cat;
      });

      await updateCatalog(catalogId, { categories: updatedCategories });

      setCategories(updatedCategories);

      Swal.fire({
        icon: "success",
        title: "Producto actualizado",
        text: "El producto ha sido actualizado correctamente.",
        timer: 1400,
        showConfirmButton: false,
      });

      handleCloseAndClean();
    } catch (error) {
      console.error("Error al actualizar producto:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el producto.",
      });
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
      style={{ zIndex: open ? 1000 : 1050 }}
    >
      <div
        ref={modalProductsRef}
        className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl flex flex-col text-center"
      >
        <h2 className="text-2xl mb-10 text-orangeFoodiv">
          {type === "new" ? "Agregar producto" : "Editar producto"}
        </h2>

        <div className="relative bg-gray-100 rounded-2xl mb-6">
          <LabelFull
            className="w-[25px] h-[25px] absolute left-2.5 text-gray-500"
            style={{ top: "50%", transform: "translateY(-50%)" }}
          />
          <input
            placeholder="Nombre del producto"
            value={producName}
            onChange={(e) => setProductName(capitalize(e.target.value))}
            className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
          />
        </div>

        <div className="relative bg-gray-100 rounded-2xl mb-6">
          <DollarSign className="w-[25px] h-[25px] absolute left-2.5 top-3.5 z-10 text-gray-500" />
          <input
            placeholder="Valor"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
          />
        </div>

        <div className="relative bg-gray-100 rounded-2xl mb-6">
          <AlignLeft className="w-[25px] h-[25px] absolute left-2.5 top-3.5 z-10 text-gray-500" />
          <textarea
            placeholder="Descripción"
            value={description}
            onChange={(e) => setDescription(capitalize(e.target.value))}
            className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
          />
        </div>

        <div className="w-full h-full flex items-center mb-4" id="ayudaAqui">
          <div className="relative bg-gray-100 rounded-2xl mb-4 flex items-center px-4 w-full">
            <Package className="w-[25px] h-[25px]  absolute left-2.5 top-3.5 z-10 text-gray-500 " />
            <select
              value={category}
              onChange={(e) => setCategory(capitalize(e.target.value))}
              className="w-full p-4 pl-6 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
              disabled={type === "edit" && true}
            >
              <option value="" disabled>
                Selecciona una categoría
              </option>
              {categories.map((category, i) => (
                <option key={i} value={category.name}>
                  {capitalize(category.name)}
                </option>
              ))}
            </select>
          </div>
          {type !== "edit" && (
            <div className="mb-4 ml-2">
              <button
                className="bg-white hover:bg-gray-200  w-full h-full rounded-lg p-3 border"
                onClick={(e) => {
                  setModalCategori({
                    open: true,
                    mode: "create",
                    categoryName: "",
                  });
                }}
              >
                <Plus className="text-gray-500" />
              </button>
            </div>
          )}
        </div>
        <div className="flex items-center space-x-4 mb-8">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="product-type"
              className="checkbox-round w-5 h-5"
              checked={isDrink}
              onChange={handleDrinkChange}
            />
            <span className="ml-2">Es bebestible</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="product-type"
              className="checkbox-round w-5 h-5"
              checked={!isDrink}
              onChange={handleEdibleChange}
            />
            <span className="ml-2">Es comestible</span>
          </label>
        </div>

        <div className="flex justify-between">
          {type === "edit" && (
            <div>
              <button
                className="flex items-center text-orangeFoodiv p-2 rounded-2xl w-auto hover:bg-orange-200 border border-orange-400"
                onClick={() => {
                  handleDeleteProdut();
                }}
              >
                <TrashIcon />
                Eliminar producto
              </button>
            </div>
          )}

          <div
            className={type !== "edit" && "w-full flex flex-row justify-end"}
          >
            <button
              onClick={() => {
                handleCloseAndClean();
              }}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                if (type === "new") {
                  createProduct();
                } else {
                  updateProduct();
                }
              }}
              className={`bg-orangeFoodiv text-white px-4 py-2 rounded-2xl ${
                !producName || !price
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-orange-400"
              }`}
              disabled={!producName || !price}
            >
              {type === "new" ? "Crear" : "Guardar"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
