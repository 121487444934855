import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { db } from "./config"; // Importa la configuración de Firestore

export const handleLoginUser = async (restaurantCode, restaurantPin) => {
  try {
    const q = query(
      collection(db, "restaurants"),
      where("code", "==", restaurantCode),
      where("pin", "==", restaurantPin),
      where("status", "==", "active"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return false; // Login fallido
    } else {
      return querySnapshot.docs[0].data();
    }
  } catch (error) {
    console.error("Error during login:", error);
    return null; // Manejo de errores
  }
};
