import React, { useState } from "react";
import Swal from "sweetalert2";
import { ReactComponent as Circle } from "../assets/icons/circle.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit-2.svg";

const PaginatedTables = ({
  filteredTables,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setModalConfig,
  setIsModalDeleteOpen,
}) => {
  const totalItems = filteredTables.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const visibleTables = filteredTables.slice(startIdx, endIdx);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="w-full">
      {visibleTables.map((table, index) => (
        <div
          key={index}
          className="flex items-center justify-between bg-white rounded-2xl shadow-sm p-5 mb-4"
        >
          <div className="flex items-center">
            <button
              onClick={() =>
                setModalConfig({
                  open: true,
                  mode: "edit",
                  id: table.number,
                  number: table.number,
                })
              }
              className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition mr-4"
            >
              <EditIcon className="text-gray-500 w-6 h-6" />
            </button>
            <p className="text-gray-700 text-base w-24">Mesa {table.number}</p>
            <div className="flex items-center ml-10">
              <Circle
                className={`w-6 h-6 p-1 ${
                  table.status === "occupied"
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              />
              <p className="text-gray-700 text-base ml-2">
                {table.status === "occupied" ? "Mesa ocupada" : "Mesa libre"}
              </p>
            </div>
          </div>
          <button
            onClick={() => {
              if (table.status === "occupied") {
                Swal.fire({
                  icon: "info",
                  title: "No es posible borrar esta mesa",
                  text: "Debe limpiar la mesa desde la app para poder eliminarla ",
                });
              } else {
                setIsModalDeleteOpen({ open: true, id: table.number });
              }
            }}
            className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition"
          >
            <TrashIcon className="text-red-500 w-6 h-6" />
          </button>
        </div>
      ))}

      {/* Controles de Paginación */}
      <div className="flex justify-center items-center mt-4 space-x-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-lg ${
            currentPage === 1 ? "bg-gray-300" : "bg-orangeFoodiv text-white"
          }`}
        >
          Anterior
        </button>
        <p className="text-gray-700">
          Página {currentPage} de {totalPages}
        </p>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-lg ${
            currentPage === totalPages
              ? "bg-gray-300"
              : "bg-orangeFoodiv text-white"
          }`}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default PaginatedTables;
