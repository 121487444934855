import { useState } from "react";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { capitalizeAll } from "../utils/functions";
import { ReactComponent as LeftArrowCircle } from "../assets/icons/arrow-left-cricle.svg";

export default function ResumeProducts() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState("productos");
  return (
    <div className="w-full">
      <div className=" w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-start px-4 py-3 mb-2">
          <button className="flex items-center justify-center rounded-3xl hover:bg-orange-100 p-2 mr-3">
            <LeftArrowCircle className="w-10 h-10 text-orangeFoodiv " />
          </button>
          <h1 className="text-orangeFoodiv text-3xl font-semibold">
            Resumen de productos
          </h1>
        </div>
        <div className="w-full flex flex-col lg:flex-row mt-8 gap-4">
   
          <div className="w-auto flex flex-col">
            <span className="text-orangeFoodiv text-lg font-semibold">
              Desde:
            </span>
            <DatePicker
              selected={startDate}
              locale={es}
              onChange={(date) => setStartDate(date)}
              selectsStart
              className="p-3 border border-orange-300 rounded-xl w-full mb-4 focus:outline-none focus:ring focus:ring-orange-200"
              placeholderText="Seleccionar fecha"
              popperPlacement="bottom"
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="w-auto flex flex-col">
            <span className="text-orangeFoodiv text-lg font-semibold">
              Hasta:
            </span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={es}
              className="p-3 border border-orange-300 rounded-xl w-full mb-4 focus:outline-none focus:ring focus:ring-orange-200"
              placeholderText="Seleccionar fecha"
              popperPlacement="bottom"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
