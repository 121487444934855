import React, { useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth_actions";
import Logo from "../assets/logo.svg";
import grid from "../assets/icons/grid.svg";
import pieChart from "../assets/icons/pie-chart.svg";
import users from "../assets/icons/users.svg";
import smartphone from "../assets/icons/smartphone.svg";
import logoutIcon from "../assets/icons/logout.svg";
import pizza from "../assets/icons/pizza.svg";
import { capitalizeAll } from "../utils/functions";
import { ReactComponent as BurgerMenu } from "../assets/icons/burger-menu.svg";

const MyDrawer = ({ drawerOpen, handleDrawerToggleDesktop }) => {
  const dataUser = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropDrawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isMobile = window.innerWidth <= 768; // Define el umbral de móvil, como 768px
      if (
        drawerOpen &&
        dropDrawerRef.current &&
        !dropDrawerRef.current.contains(event.target) &&
        isMobile // Verifica si es un dispositivo móvil
      ) {
        handleDrawerToggleDesktop();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [drawerOpen]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="relative" ref={dropDrawerRef}>
      <button
        onClick={handleDrawerToggleDesktop}
        className="fixed top-3 left-4 z-50 p-2  text-gray-700 rounded-3xl hover:bg-gray-200"
      >
        <BurgerMenu className="w-7 h-7" />
      </button>

      <div
        className={`fixed top-0 left-0 h-full bg-white transition-transform duration-300 ${
          drawerOpen ? "translate-x-0" : "-translate-x-full"
        } z-40`}
        style={{ width: "16rem" }}
      >
        <div className="flex items-center justify-center py-4 border-b px-4 w-auto h-28 mt-10">
          <img src={Logo} alt="Logo" className="h-auto w-40  min-w-52 " />
        </div>
        {dataUser && dataUser.role !== "admin" && (
          <div className="flex justify-center py-4">
            <div className="flex items-center px-4 py-4 border-2 gap-3 bg-gray-100 w-11/12 rounded-xl">
              <div className="w-12 h-12 rounded-full flex items-center justify-center">
                <img
                  src={dataUser?.image}
                  alt="User Avatar"
                  className="rounded-full h-10 w-10 "
                />
              </div>
              <div>
                <p className="font-medium text-gray-700">
                  {capitalizeAll(dataUser?.name)}
                </p>
                <p className="text-sm text-gray-500">{dataUser?.address}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 py-4 px-4 overflow-y-auto">
          <nav className="flex flex-col gap-2">
            {dataUser && dataUser.role === "admin" ? (
              <>
                <NavLink
                  to="/restaurants"
                  className={({ isActive }) =>
                    `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                      isActive
                        ? "border-2 text-orangeFoodiv bg-orange-50"
                        : "hover:bg-gray-100"
                    }`
                  }
                >
                  <img src={pizza} />
                  Restautantes
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  to="/reports"
                  className={({ isActive }) =>
                    `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                      isActive
                        ? "border-2 text-orangeFoodiv bg-orange-50"
                        : "hover:bg-gray-100"
                    }`
                  }
                >
                  <img src={pieChart} />
                  Reportes
                </NavLink>
                <NavLink
                  to="/catalogo"
                  className={({ isActive }) =>
                    `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                      isActive
                        ? "border-2 text-orangeFoodiv bg-orange-50"
                        : "hover:bg-gray-100"
                    }`
                  }
                >
                  <img src={smartphone} alt="Catálogo" />
                  Catálogo
                </NavLink>
                <NavLink
                  to="/tables"
                  className={({ isActive }) =>
                    `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                      isActive
                        ? "border-2 text-orangeFoodiv bg-orange-50"
                        : "hover:bg-gray-100"
                    }`
                  }
                >
                  <img src={grid} alt="Mesas" />
                  Mesas
                </NavLink>
                <NavLink
                  to="/waiters"
                  className={({ isActive }) =>
                    `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                      isActive
                        ? "border-2 text-orangeFoodiv bg-orange-50"
                        : "hover:bg-gray-100"
                    }`
                  }
                >
                  <img src={users} />
                  Meseros
                </NavLink>{" "}
              </>
            )}
          </nav>
        </div>

        <div className="absolute bottom-5 left-0 w-full px-4 border-t">
          <button
            className="flex items-center gap-3 px-3 py-5 w-full rounded-lg hover:bg-gray-100 text-red-700"
            onClick={handleLogout}
          >
            <img src={logoutIcon} />
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyDrawer;
