import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import Swal from "sweetalert2";
import { deleteRestorantById } from "../database/restaurant";
const Pagination = ({
  items,
  itemsPerPage,
  setRestorantsArray,
  handleOpenModal,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleDeleteRestaurant = async (id) => {
    console.log("id", id);
    Swal.fire({
      icon: "warning",
      title: "¿Estas seguro de eliminar este restaurante?",
      text: "Si eliminas este restaurante se eliminaran todos los datos asociados",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (e) => {
      console.log("e", e);
      if (e.isConfirmed) {
        const a = await deleteRestorantById(id);
        if (a.success) {
          const updatedWaiters = items.filter((waiter) => waiter.id !== id);
          setRestorantsArray(updatedWaiters);
          Swal.fire({
            icon: "success",
            title: "Completado",
            text: a.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "upsss",
            text: "hubo un error al tratar de eliminar este restaurante",
          });
        }
      }
    });
  };
  return (
    <div>
      <div>
        {currentItems.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-white rounded-2xl shadow-sm p-5 mb-4"
          >
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-gray-200 mr-10">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-full rounded-full"
                />
              </div>

              <p className="text-gray-700 text-base w-50 font-semibold">
                {item.name}
              </p>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => {
                  handleOpenModal("edit", item);
                }}
                className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition mr-4"
              >
                <EditIcon className="text-gray-500 w-6 h-6" />
              </button>
              <button
                onClick={() => {
                  handleDeleteRestaurant(item.id);
                }}
                className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition"
              >
                <TrashIcon className="text-red-500 w-6 h-6" />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 text-white bg-gray-600 rounded disabled:opacity-50"
        >
          Anterior
        </button>
        <span className="px-4 py-2 mx-1 text-gray-700">
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 mx-1 text-white bg-gray-600 rounded disabled:opacity-50"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Pagination;
