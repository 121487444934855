import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../database/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
export const getRestorants = async () => {
  try {
    const queryRestorants = query(collection(db, "restaurants"));
    const restorantsSnapshot = await getDocs(queryRestorants);

    const restorants = restorantsSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((restorant) => restorant.role !== "admin");
    return restorants;
  } catch (error) {
    console.log("Error getRestorants", error);
  }
};

export const uploadImageToStorage = async (image, name) => {
  console.log("image", image);
  console.log("name", name);

  try {
    const storageRef = ref(storage, `/logos/${name}-${Date.now()}`);

    // Asegúrate de pasar los metadatos correctos (tipo de archivo)
    const metadata = {
      contentType: image.type || "image/jpeg", // Ajuste predeterminado si no se puede determinar
    };

    const uploadResult = await uploadBytes(storageRef, image, metadata);
    console.log("Upload result:", uploadResult); // Verifica los detalles de la subida
    const imageURL = await getDownloadURL(uploadResult.ref);
    console.log("Image URL:", imageURL); // Verifica la URL obtenida

    return imageURL; // Devuelve la URL de la imagen almacenada
  } catch (error) {
    console.error("Error uploading image:", error);
    throw new Error("Error al subir la imagen");
  }
};
export const createRestorant = async (data) => {
  try {
    const { image, name, address, pin, users, status } = data; // Desestructurar los datos recibidos

    let imageURL = "";
    if (image) {
      imageURL = await uploadImageToStorage(image, name);
    }
    const newRestaurant = {
      name,
      address,
      pin,
      users,
      status,
      pos: [],
      tables: [],
      image: imageURL,
    };

    const queryRestorants = query(collection(db, "restaurants"));
    const restorantsSnapshot = await getDocs(queryRestorants);

    const restorants = restorantsSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((restorant) => restorant.role !== "admin");

    const docRef = await addDoc(collection(db, "restaurants"), newRestaurant);
    await setDoc(docRef, {
      ...newRestaurant,
      id: docRef.id,
    });
    return [...restorants, { id: docRef.id, ...newRestaurant }];
  } catch (error) {
    console.log("Error creating restaurant", error);
    throw new Error("Error al crear el restaurante");
  }
};

export const editRestorantById = async (data) => {
  try {
    const { id, name, address, pin, code } = data;

    // Si `image` es un objeto File (imagen nueva), súbela a Storage

    const updatedRestorant = {
      id,
      name,
      address,
      pin,
      code,
    };
    if (data.image) {
      let imageURL = await uploadImageToStorage(data.image, name);
      updatedRestorant.image = imageURL;
    }
    // Referencia al restaurante en Firestore usando el ID
    const restaurantRef = doc(db, "restaurants", id);

    // Actualiza el documento en Firestore
    await setDoc(restaurantRef, updatedRestorant, { merge: true });

    const queryRestorants = query(collection(db, "restaurants"));
    const restorantsSnapshot = await getDocs(queryRestorants);

    const restorants = restorantsSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((restorant) => restorant.role !== "admin");

    return restorants; // Devuelve el array actualizado
  } catch (error) {
    console.error("Error al editar el restaurante:", error);
    throw new Error("Error al editar el restaurante.");
  }
};

export const deleteRestorantById = async (id) => {
  try {
    // Referencia al documento del restaurante usando el ID
    const restaurantRef = doc(db, "restaurants", id);

    // Eliminar el documento
    await deleteDoc(restaurantRef);

    console.log(`Restaurante con ID ${id} eliminado correctamente.`);
    return { success: true, message: "Restaurante eliminado correctamente." };
  } catch (error) {
    console.error(`Error al eliminar el restaurante con ID ${id}:`, error);
    throw new Error("Error al eliminar el restaurante.");
  }
};
