import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg";
import { capitalize, formatToCLP } from "../utils/functions";
const PaginationCatalog = ({ items, itemsPerPage, handleEditProduct }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calcular el índice de los productos visibles
  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const visibleItems = items.slice(startIdx, endIdx);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="w-auto h-auto">
      {visibleItems.map((product, index) => (
        <div
          key={index}
          className="w-full h-[80px] bg-white border rounded-3xl p-5 flex flex-row items-center justify-between mb-4 cursor-pointer shadow-sm hover:shadow-gray-400"
          onClick={() => handleEditProduct(product)}
        >
          <div className="flex-1">
            <p className="text-gray-950 text-lg">{capitalize(product.name)}</p>
            <p className="text-gray-600 text-md font-extralight">
              {capitalize(product.description) || "Sin descripción"}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <p className="text-gray-800 text-md font-medium">
              {formatToCLP(product.price)}
            </p>
            <ChevronRight className="text-gray-800" />
          </div>
        </div>
      ))}

      {/* Controles de Paginación */}
      <div className="flex justify-center items-center mt-4 space-x-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-lg ${
            currentPage === 1 ? "bg-gray-300" : "bg-orangeFoodiv text-white"
          }`}
        >
          Anterior
        </button>
        <p className="text-gray-700">
          Página {currentPage} de {totalPages}
        </p>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-lg ${
            currentPage === totalPages
              ? "bg-gray-300"
              : "bg-orangeFoodiv text-white"
          }`}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};
export default PaginationCatalog;
