import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../database/config";
import firebase from "firebase/compat/app";

export const getDailyReport = async (initDate, endDate, restaurantId) => {
  try {
    const startTimestamp = Timestamp.fromDate(new Date(initDate));
    const endTimestamp = Timestamp.fromDate(new Date(endDate));
    const dateInitObj = new Date(initDate);
    const dateEndObj = new Date(endDate);

    if (isNaN(dateInitObj) || isNaN(dateEndObj)) {
      throw new Error("Formato de fecha no válido");
    }

    const paysRef = collection(db, "pays");

    const currentDayQuery = query(
      paysRef,
      where("restaurantId", "==", restaurantId),
      where("payDate", ">=", startTimestamp),
      where("payDate", "<=", endTimestamp),
      where("status", "==", "accepted")
    );

    const [currentDaySnapshot] = await Promise.all([getDocs(currentDayQuery)]);

    const calculateTotals = (snapshot) => {
      let totals = {
        total: 0,
        tips: 0,
        cashPays: 0,
        cardPays: 0,
        paysCash: 0,
        paysDebit: 0,
        paysCredit: 0,
      };

      snapshot.forEach((doc) => {
        const data = doc.data();

        // Inicializamos el total si no existe y limpiamos el campo amountTotalPay
        if (data.amountTotalPay) {
          totals.total +=
            parseInt(data.amountTotalPay.replace(/\D/g, ""), 10) || 0;
        }

        // Aseguramos que infoPays sea un array válido
        (data.infoPays || []).forEach((pay) => {
          // Procesamos cada elemento dentro de pay.pays
          (pay.pays || []).forEach((e) => {
            const amount = parseInt(e.amount.replace(/\D/g, ""), 10) || 0;

            // Contamos y sumamos según el tipo de pago
            if (e.typePay === "Efectivo") {
              totals.cashPays++;
              totals.paysCash += amount;
            } else if (e.typePay?.toLowerCase() === "tarjeta de débito") {
              totals.cardPays++;
              totals.paysDebit += amount;
            } else if (e.typePay?.toLowerCase() === "tarjeta de crédito") {
              totals.cardPays++;
              totals.paysCredit += amount;
            }

            // Verificamos y sumamos la propina si corresponde
            if (e.tip && e.tip !== "Sin propina") {
              totals.tips += parseInt(e.tip.replace(/\D/g, ""), 10) || 0;
            }
          });

          // Procesamos el monto general del pago
          const amount = parseInt(pay.amount.replace(/\D/g, ""), 10) || 0;
          if (pay.typePay === "Efectivo") {
            totals.cashPays++;
            totals.paysCash += amount;
          } else if (pay.typePay?.toLowerCase() === "tarjeta de débito") {
            totals.cardPays++;
            totals.paysDebit += amount;
          } else if (pay.typePay?.toLowerCase() === "tarjeta de crédito") {
            totals.cardPays++;
            totals.paysCredit += amount;
          }

          // Procesamos la propina general del pago
          if (pay.tip && pay.tip !== "Sin propina") {
            totals.tips += parseInt(pay.tip.replace(/\D/g, ""), 10) || 0;
          }
        });
      });

      return totals;
    };

    const dataToday = calculateTotals(currentDaySnapshot);

    return { dataToday };
  } catch (error) {
    console.error("Error al obtener el reporte diario:", error);
    return false;
  }
};

export const getProductsReport = async (initDate, endDate, restaurantId) => {
  try {
    const startTimestamp = Timestamp.fromDate(new Date(initDate));
    const endTimestamp = Timestamp.fromDate(new Date(endDate));

    const paysRef = collection(db, "pays");
    const paysQuery = query(
      paysRef,
      where("restaurantId", "==", restaurantId),
      where("payDate", ">=", startTimestamp),
      where("payDate", "<=", endTimestamp)
    );

    const paysSnapshot = await getDocs(paysQuery);

    const orderIds = [];
    paysSnapshot.forEach((doc) => {
      const orderId = doc.data().orderId;
      if (typeof orderId === "string") {
        orderIds.push(orderId);
      }
    });

    const ordersDocs = [];
    for (const oId of orderIds) {
      const orderDoc = await getDoc(doc(db, "orders", oId));
      if (orderDoc.exists()) {
        ordersDocs.push(orderDoc);
      }
    }

    const foodCounts = {};
    const drinkCounts = {};

    ordersDocs.forEach((orderDoc) => {
      const data = orderDoc.data();
      const diners = data.diners || [];

      diners.forEach((diner) => {
        const products = diner.orders || [];

        products.forEach((prod) => {
          const productName = prod.name || "Sin nombre";
          const quantity = parseInt(prod.quantity, 10) || 0;
          const isDrink = prod.isDrink || false;
          const unitPrice = prod.unitPrice ? prod.unitPrice.toString() : "0";

          if (isDrink) {
            if (drinkCounts[productName]) {
              drinkCounts[productName].quantity += quantity;
            } else {
              drinkCounts[productName] = {
                quantity,
                unitPrice,
              };
            }
          } else {
            if (foodCounts[productName]) {
              foodCounts[productName].quantity += quantity;
            } else {
              foodCounts[productName] = {
                quantity,
                unitPrice,
              };
            }
          }
        });
      });
    });

    const isFoodList = Object.entries(foodCounts).map(([product, value]) => ({
      product,
      ...value,
    }));

    const isDrinkList = Object.entries(drinkCounts).map(([product, value]) => ({
      product,
      ...value,
    }));

    return {
      isFood: isFoodList,
      isDrink: isDrinkList,
    };
  } catch (error) {
    console.error("Error al obtener el reporte de productos:", error);
    return false;
  }
};

export const geyMonthlyReport = async (restaurantId) => {
  try {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth() - 4, 1);
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const startTimestamp = Timestamp.fromDate(startDate);
    const endTimestamp = Timestamp.fromDate(endDate);

    const paysRef = collection(db, "pays");
    const paysQuery = query(
      paysRef,
      where("restaurantId", "==", restaurantId),
      where("payDate", ">=", startTimestamp),
      where("payDate", "<=", endTimestamp),
      where("status", "==", "accepted")
    );

    const paysSnapshot = await getDocs(paysQuery);

    const paysData = [];
    paysSnapshot.forEach((doc) => {
      const data = doc.data();
      paysData.push({
        id: doc.id,
        ...data,
      });
    });

    const monthlyTotals = {};
    paysData.forEach((item) => {
      if (item.status !== "accepted") return;

      const date = item.payDate.toDate();
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;

      const amount = parseInt(item.amountTotalPay.replace(/[^\d]/g, ""), 10);

      if (!monthlyTotals[monthKey]) {
        monthlyTotals[monthKey] = 0;
      }
      monthlyTotals[monthKey] += amount;
    });

    const dataPoints = [];
    for (let i = 4; i >= 0; i--) {
      const monthDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const monthKey = `${monthDate.getFullYear()}-${monthDate.getMonth() + 1}`;
      dataPoints.push(monthlyTotals[monthKey] || 0);
    }

    return dataPoints;
  } catch (error) {
    console.error("Error al obtener el reporte mensual:", error);
    return false;
  }
};
