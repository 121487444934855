import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import MyDrawer from "../components/drawer";
import { db } from "../database/config";
import { doc, onSnapshot } from "firebase/firestore";
import { useSelector } from "react-redux";

const appBarHeight = 45; // Altura del AppBar

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detecta si es móvil
  const userId = useSelector((state) => state.auth.idUser);
  const [availableSheets, setAvailableSheets] = useState([]);

  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "workspaces", userId);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setAvailableSheets(data.hojas || []);
      } else {
        setAvailableSheets([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  // Escuchar cambios en el tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggleDesktop = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className="flex w-full h-full" style={{ backgroundColor: "#f7f7f8" }}>
      <MyDrawer
        drawerOpen={drawerOpen}
        handleDrawerToggleDesktop={handleDrawerToggleDesktop}
        availableSheets={availableSheets}
      />
      <main
        className={`flex-grow p-4 mt-[25px] transition-all duration-300`}
        style={{
          marginLeft: !isMobile && drawerOpen ? "16rem" : "0", // Empuja en escritorio, superpone en móvil
          height: `calc(100vh - ${appBarHeight}px)`,
        }}
      >
        <Outlet context={{ availableSheets }} />
      </main>
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Layout;
