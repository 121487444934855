import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Lens } from "../assets/icons/lens.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as BookOpen } from "../assets/icons/book-open.svg";
import { ReactComponent as Box } from "../assets/icons/box.svg";

import { ReactComponent as MenuVert } from "../assets/icons/menu-vert.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";

import loading from "../assets/logo_animado.svg";
import {
  getCatalogsByRestaurantId,
  updateCatalog,
  deleteProduct,
  createNewCatalog,
} from "../database/catalog";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { capitalize, capitalizeAll, formatToCLP } from "../utils/functions";
import DialogProducts from "../components/dialog_products";
import PaginationCatalog from "../components/pagination_catalog";
const Catalogo = () => {
  const restaurantId = useSelector((state) => state.auth.userData.id);
  const modalCategoriRef = useRef(null);
  const modalProductsRef = useRef(null);
  const dropMenuRef = useRef(null);
  const [catalogoData, setCatalogoData] = useState([]);
  const [modalCategori, setModalCategori] = useState({
    open: false,
    mode: "create",
    categoryName: "",
  });
  const [modalProducts, setModalProducts] = useState({
    open: false,
    mode: "create",
    categoryName: "",
    producName: "",
    price: "",
    description: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState("todas");
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isDrink, setIsDrink] = useState(false);
  const [catalogId, setCatalogId] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [productDialogType, setProductDialogType] = useState("new");
  const closeProductModal = () => setOpenProductModal(false);
  const [dataProductUpdate, setDataProductUpdate] = useState("");

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (action) => {
    if (action === "newCategory") {
      setModalCategori({ open: true, mode: "create", categoryName: "" });
    } else if (action === "editCategory") {
      if (!selectedTab || selectedTab === "todas") {
        Swal.fire({
          icon: "warning",
          title: "Categoría no seleccionada",
          text: "Por favor selecciona una categoría para editar.",
          confirmButtonText: "Aceptar",
          timer: 1400,
        });
        return;
      } else {
        setModalCategori({
          open: true,
          mode: "edit",
          categoryName: selectedTab,
        });
      }
    } else if (action === "deleteCategory") {
      handleDeleteCategory();
    }
    // setIsMenuOpen(false);
  };
  useEffect(() => {
    const updateProducts = () => {
      if (selectedTab === "todas") {
        setAllProducts(categories.flatMap((category) => category.products));
      } else {
        const category = categories.find((cat) => cat.name === selectedTab);
        setAllProducts(category ? category.products : []);
      }
    };

    updateProducts();
  }, [selectedTab, categories]);

  useEffect(() => {
    const fetchCatalogs = async () => {
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      try {
        const catalogs = await getCatalogsByRestaurantId(restaurantId);
        if (catalogs.length > 0) {
          const catalogData = catalogs[0];
          setCatalogoData(catalogData);
          setCatalogId(catalogData.id);
          setCategories(catalogData.categories || []);
          setAllProducts(
            catalogData.categories.flatMap((category) => category.products)
          );
        } else {
          setCatalogoData(null);
          setCategories([]);
        }
      } finally {
        Swal.close();
      }
    };

    fetchCatalogs();
  }, [restaurantId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalCategori.open &&
        modalCategoriRef.current &&
        !modalCategoriRef.current.contains(event.target)
      ) {
        setModalCategori({ open: false, mode: "create", categoryName: "" });
      }

      if (
        isMenuOpen &&
        dropMenuRef.current &&
        !dropMenuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [modalCategori.open, isMenuOpen]);

  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Ajusta la velocidad de desplazamiento
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    isDragging = false;
    scrollContainerRef.current.style.cursor = "grab";
  };

  const handleModalSubmit = async () => {
    if (
      !modalCategori.categoryName ||
      modalCategori.categoryName.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Campo vacío",
        text: "Por favor, ingresa un nombre para la categoría.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    const isDuplicate = categories.some(
      (category) =>
        category.name.toLowerCase() === modalCategori.categoryName.toLowerCase()
    );
    if (isDuplicate) {
      Swal.fire({
        icon: "warning",
        title: "Nombre duplicado",
        text: "Ya existe una categoría con este nombre. Intenta con otro.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    if (modalCategori.mode === "create" && modalCategori.categoryName) {
      const newCategory = {
        name: modalCategori.categoryName,
        products: [],
      };

      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);

      try {
        if (!catalogoData || !catalogoData.id) {
          const catalogId = await createNewCatalog(
            restaurantId,
            updatedCategories
          );
          setCatalogoData({ id: catalogId, categories: updatedCategories });
        } else {
          await updateCatalog(catalogoData.id, {
            categories: updatedCategories,
          });
          setCatalogoData((prev) => ({
            ...prev,
            categories: updatedCategories,
          }));
        }

        setSelectedTab(modalCategori.categoryName);
        setModalProducts((prevState) => ({
          ...prevState,
          categoryName: modalCategori.categoryName,
        }));

        Swal.fire({
          icon: "success",
          title: "Categoría creada",
          text: "La categoría se creó correctamente.",
          confirmButtonText: "Aceptar",
          timer: 1400,
        });
      } catch (error) {
        console.error("Error al manejar el modal de categoría:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al crear la categoría. Inténtalo de nuevo.",
          confirmButtonText: "Aceptar",
          timer: 1400,
        });
      }
    } else if (modalCategori.mode === "edit" && modalCategori.categoryName) {
      const updatedCategories = categories.map((category) =>
        category.name === selectedTab
          ? { ...category, name: modalCategori.categoryName }
          : category
      );

      console.log("updatedCategories", updatedCategories);
      setSelectedTab(modalCategori.categoryName);

      setCategories(updatedCategories);

      if (catalogoData && catalogoData.id) {
        try {
          await updateCatalog(catalogoData.id, {
            categories: updatedCategories,
          });
          Swal.fire({
            icon: "success",
            title: "Categoría actualizada",
            text: "La categoría se actualizó correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        } catch (error) {
          console.error("Error al actualizar la categoría:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error al actualizar la categoría. Inténtalo de nuevo.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    }

    setModalCategori({ open: false, mode: "create", categoryName: "" });
  };

  const handleDeleteCategory = async () => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (!selectedTab || selectedTab === "todas") {
      Swal.fire({
        icon: "warning",
        title: "Categoría no seleccionada",
        text: "Por favor selecciona una categoría para eliminar.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }
    const updatedCategories = categories.filter(
      (category) => category.name !== selectedTab
    );
    if (updatedCategories.length === categories.length) {
      Swal.fire({
        icon: "warning",
        title: "Categoría inexistente",
        text: "La categoría no existe.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "¿Estas seguro que deseas eliminar esta categoría?",
      text: "al eliminar la categoría se borraran todos los productos asociados a esta misma.",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "cancelar",
      confirmButtonText: "Si, elimnar",
    }).then(async (e) => {
      if (e.isConfirmed) {
        Swal.fire({
          imageUrl: loading,
          imageWidth: 208,
          imageHeight: 209,
          title: "Cargando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        try {
          await updateCatalog(catalogoData.id, {
            categories: updatedCategories,
          });
          setCategories(updatedCategories);
          if (selectedTab === selectedTab) {
            setSelectedTab("todas");
          }
          Swal.fire({
            icon: "success",
            title: "Categoría Eliminada",
            text: "Categoría eliminada correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        } catch (error) {
          console.error("Error al eliminar la categoría:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error al eliminar la categoría. Inténtalo nuevamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    });
  };

  const handleDeleteProdut = async () => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (!modalProducts.producName) {
      Swal.fire({
        icon: "warning",
        title: "Producto no seleccionado",
        text: "Por favor selecciona un producto para eliminar.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Estas por borrar un producto.",
      text: "¿seguro que quieres eliminar este producto?",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar",
    }).then(async (e) => {
      if (e.isConfirmed) {
        try {
          await deleteProduct(modalProducts.producName, restaurantId);
          const updatedCategories = categories.map((category) => {
            return {
              ...category,
              products: category.products.filter(
                (product) => product.name !== modalProducts.producName
              ),
            };
          });
          setCategories(updatedCategories);

          Swal.fire({
            icon: "success",
            title: "Producto Eliminado",
            text: "Producto eliminado correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });

          setModalProducts({
            open: false,
            mode: "create",
            categoryName: "",
            producName: "",
            price: "",
            description: "",
          });
        } catch (error) {
          console.error("Error al eliminar el producto:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error al eliminar el producto. Inténtalo nuevamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    });
  };

  const handleEditProduct = (product) => {
    const category = categories.find((cat) =>
      cat.products.some((prod) => prod.name === product.name)
    );
    setDataProductUpdate({
      producName: product.name,
      price: product.price,
      isDrink: product.isDrink,
      description: product.description,
      categoryName: category.name,
    });

    setProductDialogType("edit");
    setOpenProductModal(true);
  };

  const handleModalProductSubmit = async () => {
    if (
      !modalProducts.categoryName ||
      modalProducts.categoryName.trim() === "" ||
      !modalProducts.producName ||
      modalProducts.producName.trim() === "" ||
      !modalProducts.price ||
      modalProducts.price.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Campos vacíos",
        text: "Por favor, completa todos los campos obligatorios.",
        confirmButtonText: "Aceptar",
      });
    }
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let updatedCategories;
    if (modalProducts.mode === "create") {
      updatedCategories = categories.map((category) => {
        if (category.name === modalProducts.categoryName) {
          return {
            ...category,
            products: [
              ...category.products,
              {
                name: modalProducts.producName,
                price: modalProducts.price,
                description: modalProducts.description,
                isDrink: isDrink,
              },
            ],
          };
        }
        return category;
      });
    } else if (modalProducts.mode === "edit") {
      updatedCategories = categories.map((category) => {
        if (category.name === modalProducts.categoryName) {
          const updatedProducts = category.products.map((product) => {
            if (product.name === modalProducts.producName) {
              return {
                ...product,
                name: modalProducts.producName,
                price: modalProducts.price,
                description: modalProducts.description,
                isDrink: isDrink,
              };
            }
            return product;
          });
          return { ...category, products: updatedProducts };
        }
        return category;
      });
    }

    try {
      await updateCatalog(catalogoData.id, { categories: updatedCategories });
      setCategories(updatedCategories);
      Swal.fire({
        icon: "success",
        title: "Producto actualizado",
        text: "El producto se guardó correctamente.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
    } catch (error) {
      console.error("Error updating product in catalog:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el producto. Inténtalo nuevamente.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
    }
    setIsDrink(false);
    setModalProducts({
      open: false,
      mode: "create",
      categoryName: "",
      producName: "",
      price: "",
      description: "",
    });
  };

  const whithContent = (
    <>
      <div className="relative grid gap-2 my-10 px-4">
        <div className="relative bg-gray-200 text-gray-700 rounded-3xl mb-6">
          <Lens
            className="w-[25px] h-[25px] absolute left-2.5 text-gray-500 "
            style={{ top: "50%", transform: "translateY(-50%)" }}
          />
          <input
            id="restaurantCode"
            type="text"
            placeholder="Buscar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0 bg-gray-200 "
          />
        </div>
        <div className="relative"></div>
      </div>

      <div
        ref={scrollContainerRef}
        className="flex justify-start mb-10 px-4 overflow-x-auto scrollbar-hide"
        style={{ scrollbarWidth: "none", cursor: "grab" }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
      >
        {["todas", ...categories.map((category) => category.name)].map(
          (tab) => (
            <button
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`px-4 py-2 mx-2 rounded-2xl ${
                selectedTab === tab
                  ? "bg-orangeFoodiv text-white"
                  : "bg-gray-200"
              }`}
            >
              {capitalizeAll(tab)}
            </button>
          )
        )}
      </div>

      {selectedTab === "todas" && (
        <PaginationCatalog
          items={allProducts.filter((product) =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          itemsPerPage={10}
          handleEditProduct={handleEditProduct}
        />
      )}

      {selectedTab !== "todas" && (
        <div className="w-auto h-auto">
          {categories
            .filter((category) => category.name === selectedTab)
            .map((category) => (
              <div key={category.name}>
                {category.products.length > 0 ? (
                  <PaginationCatalog
                    items={category.products.filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )}
                    itemsPerPage={10}
                    handleEditProduct={handleEditProduct}
                  />
                ) : (
                  <div className="w-auto h-[60dvh] flex items-center justify-center ">
                    <div className="w-[60vw] h-auto bg-white border rounded-2xl p-8 text-center ">
                      <Box className="mx-auto mb-7 text-gray-600 w-16 h-16" />
                      <p className="text-gray-800 mb-6 text-lg">
                        Ningún producto registrado, crea tu primer producto.
                      </p>
                      <button
                        className="pl-6 pr-4 py-2 flex items-center w-full justify-center rounded-2xl border border-gray-300 bg-transparent text-gray-500 hover:bg-orange-100"
                        onClick={() => {
                          setProductDialogType("new");
                          setOpenProductModal(true);
                        }}
                      >
                        <Box className="mr-2" />
                        Crear producto
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );

  const whithOutCategori = (
    <div className="w-auto h-[80vh] flex items-center justify-center ">
      <div className="w-[60vw] h-auto bg-white border rounded-2xl p-8 text-center ">
        <BookOpen className="mx-auto mb-4 text-gray-600 w-16 h-16" />
        <p className="text-gray-800 mb-6 text-lg">
          Ningúna categoría registrada, crea tu primera categoría.
        </p>
        <button
          className="pl-6 pr-4 py-2 flex items-center w-full justify-center rounded-2xl border border-gray-300 bg-transparent text-gray-500 hover:bg-orange-100"
          onClick={() => {
            setModalCategori({ open: true, mode: "create", categoryName: "" });
          }}
        >
          <Plus className="mr-2" />
          Agregar categoría
        </button>
      </div>
    </div>
  );
  // const filteredProducts = allProducts.filter((product) =>
  //   product.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  return (
    <div className="w-full h-screen flex flex-col">
      <div className=" w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-between px-4 py-3 mb-2">
          <h1 className="text-orangeFoodiv text-3xl font-semibold">Catálogo</h1>

          <div className="relative">
            <button
              className="w-12 h-12 shadow-md border border-gray-100 bg-white rounded-xl flex items-center justify-center"
              onClick={toggleMenu}
            >
              <MenuVert className="w-6 h-6 text-gray-800" />
            </button>

            {isMenuOpen && (
              <div
                className="absolute right-0 mt-1 bg-white border border-gray-100 shadow-lg rounded-2xl w-auto z-50"
                ref={dropMenuRef}
              >
                <button
                  className="w-full flex items-center text-left px-4 py-4 hover:bg-orange-50 text-orangeFoodiv whitespace-nowrap rounded-t-2xl"
                  onClick={() => handleMenuItemClick("newCategory")}
                >
                  <Plus className="mr-2" />
                  Agregar categoría
                </button>
                <button
                  className="w-full flex items-center text-left px-4 py-4 hover:bg-orange-50 text-orangeFoodiv whitespace-nowrap"
                  onClick={() => handleMenuItemClick("editCategory")}
                >
                  <EditIcon className="mr-2" />
                  Editar categoría
                </button>
                <button
                  className="w-full flex items-center text-left px-4 py-4 hover:bg-orange-50 text-orangeFoodiv whitespace-nowrap rounded-b-2xl"
                  onClick={() => handleMenuItemClick("deleteCategory")}
                >
                  <TrashIcon className="mr-2" />
                  Eliminar categoría
                </button>
              </div>
            )}
          </div>
        </div>

        {categories.length === 0 ? whithOutCategori : whithContent}
        {modalCategori.open && (
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
            style={{ zIndex: 1050 }}
          >
            <div
              ref={modalCategoriRef}
              className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col text-center"
            >
              <h2 className="text-2xl mb-4 text-orangeFoodiv">
                {modalCategori.mode === "create"
                  ? "Agregar categoría"
                  : "Editar categoría"}
              </h2>

              <input
                placeholder="Ingresa categoría"
                value={capitalize(modalCategori.categoryName)}
                onChange={(e) =>
                  setModalCategori({
                    ...modalCategori,
                    categoryName: capitalize(e.target.value),
                  })
                }
                className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
              />
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    setModalCategori({
                      open: false,
                      mode: "create",
                      categoryName: "",
                    })
                  }
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleModalSubmit}
                  className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
                >
                  {modalCategori.mode === "create" ? "Crear" : "Guardar"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <DialogProducts
        open={openProductModal}
        type={productDialogType}
        restaurantId={restaurantId}
        categories={categories}
        updateData={dataProductUpdate}
        onClose={closeProductModal}
        catalogId={catalogId}
        setCategories={setCategories}
        categorySelected={categorySelected}
        setModalCategori={setModalCategori}
      />
    </div>
  );
};

export default Catalogo;
